html {
  background-color: #3face1;
}
@media (min-width: 768px) {
  .q-layout {
    overflow-y: scroll;
  }
}
.bg-image:before,
.bg-image:after {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.bg-image:before {
  background-image: url("~assets/Mobilleo Wave.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
@media (min-width: 768px) {
  .bg-image {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    animation: carousel 48s infinite ease-out;
  }
  .bg-image:after {
    height: 0;
    width: 0;
    z-index: -1;
    position: absolute;
    overflow: hidden;
    content: url("~assets/login/Bike.png") url("~assets/login/Bus.png") url("~assets/login/BusStop.png") url("~assets/login/Scooter.png") url("~assets/login/Ticket.png") url("~assets/login/Tram.png") url("~assets/login/Tube.png");
  }
}
.q-page {
  z-index: 10;
}
.login-layout {
  min-width: 425px;
}
.login-layout >>> .q-btn.text-white {
  color: #fff;
}
.desktop {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-25%);
}
.logo {
  width: 70%;
  max-width: 300px;
}
@-moz-keyframes carousel {
  0%, 2.5% {
    background-image: url("~assets/login/Airport.png");
  }
  10%, 12.5%, 15% {
    background-image: url("~assets/login/Bike.png");
  }
  22.5%, 25%, 27.5% {
    background-image: url("~assets/login/Bus.png");
  }
  35%, 37.5%, 40% {
    background-image: url("~assets/login/BusStop.png");
  }
  47.5%, 50%, 52.5% {
    background-image: url("~assets/login/Scooter.png");
  }
  60%, 62.5%, 65% {
    background-image: url("~assets/login/Ticket.png");
  }
  72.5%, 75%, 77.5% {
    background-image: url("~assets/login/Tram.png");
  }
  85%, 87.5%, 90% {
    background-image: url("~assets/login/Tube.png");
  }
  97.5%, 100% {
    background-image: url("~assets/login/Airport.png");
  }
}
@-webkit-keyframes carousel {
  0%, 2.5% {
    background-image: url("~assets/login/Airport.png");
  }
  10%, 12.5%, 15% {
    background-image: url("~assets/login/Bike.png");
  }
  22.5%, 25%, 27.5% {
    background-image: url("~assets/login/Bus.png");
  }
  35%, 37.5%, 40% {
    background-image: url("~assets/login/BusStop.png");
  }
  47.5%, 50%, 52.5% {
    background-image: url("~assets/login/Scooter.png");
  }
  60%, 62.5%, 65% {
    background-image: url("~assets/login/Ticket.png");
  }
  72.5%, 75%, 77.5% {
    background-image: url("~assets/login/Tram.png");
  }
  85%, 87.5%, 90% {
    background-image: url("~assets/login/Tube.png");
  }
  97.5%, 100% {
    background-image: url("~assets/login/Airport.png");
  }
}
@-o-keyframes carousel {
  0%, 2.5% {
    background-image: url("~assets/login/Airport.png");
  }
  10%, 12.5%, 15% {
    background-image: url("~assets/login/Bike.png");
  }
  22.5%, 25%, 27.5% {
    background-image: url("~assets/login/Bus.png");
  }
  35%, 37.5%, 40% {
    background-image: url("~assets/login/BusStop.png");
  }
  47.5%, 50%, 52.5% {
    background-image: url("~assets/login/Scooter.png");
  }
  60%, 62.5%, 65% {
    background-image: url("~assets/login/Ticket.png");
  }
  72.5%, 75%, 77.5% {
    background-image: url("~assets/login/Tram.png");
  }
  85%, 87.5%, 90% {
    background-image: url("~assets/login/Tube.png");
  }
  97.5%, 100% {
    background-image: url("~assets/login/Airport.png");
  }
}
@keyframes carousel {
  0%, 2.5% {
    background-image: url("~assets/login/Airport.png");
  }
  10%, 12.5%, 15% {
    background-image: url("~assets/login/Bike.png");
  }
  22.5%, 25%, 27.5% {
    background-image: url("~assets/login/Bus.png");
  }
  35%, 37.5%, 40% {
    background-image: url("~assets/login/BusStop.png");
  }
  47.5%, 50%, 52.5% {
    background-image: url("~assets/login/Scooter.png");
  }
  60%, 62.5%, 65% {
    background-image: url("~assets/login/Ticket.png");
  }
  72.5%, 75%, 77.5% {
    background-image: url("~assets/login/Tram.png");
  }
  85%, 87.5%, 90% {
    background-image: url("~assets/login/Tube.png");
  }
  97.5%, 100% {
    background-image: url("~assets/login/Airport.png");
  }
}
/*# sourceMappingURL=src/layouts/login/mobilleo/index.css.map */